<template>
  <div class="u-page">
    <IndexSlider
      class="mb-l"
      :slides="slides"
      :pending="bannersPending" />

    <div class="link-title mb-s">
      <h1 class="text-size-h1 text-bold link-title__text">
        Мои ученики
        <span
          v-if="total"
          class="text-size-m"
          >{{ filtredStudents.length }} из {{ total }}</span
        >
      </h1>
      <router-link
        class="link-title__link"
        :to="{ name: 'students-achievements' }"
        >Достижения&nbsp;<BaseIcon glyph="arrow-right"
      /></router-link>
    </div>
    <div
      v-if="error"
      class="form-error mb-l">
      {{ error }}
    </div>
    <div v-if="pending">
      <BaseSpinner brand />
    </div>

    <div v-if="students.length">
      <div>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-xs-12 mb-m">
            <BaseSelect
              v-model="filters.track"
              :options="trackOptions"
              label="title"
              track-by="id"
              :allow-empty="false"
              placeholder="Выбрать трек"
              preselect-first
              class="mb-s"></BaseSelect>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-12 mb-m">
            <BaseSelect
              v-model="filters.grade"
              :options="gradeOptions"
              label="title"
              track-by="id"
              :allow-empty="false"
              placeholder="Выбрать класс"
              preselect-first
              class="mb-s"></BaseSelect>
          </div>
          <div
            v-if="profilesOptions && profilesOptions.length"
            class="col-lg-6 col-md-4 col-xs-12 mb-m">
            <BaseSelect
              v-model="filters.profile"
              :options="profilesOptions"
              label="title"
              track-by="id"
              :allow-empty="true"
              placeholder="Выбрать профиль"
              class="mb-s"></BaseSelect>
          </div>
        </div>
      </div>
      <div
        v-for="student in filtredStudents"
        :key="student.id"
        class="u-page__card"
        tabindex="0"
        @click="handleUserClick(student.id)"
        @keydown.enter="handleUserClick(student.id)">
        <UserCard
          :avatar="student.avatar"
          class="mb-xs">
          <template #name
            >{{ student.first_name }} {{ student.last_name }}</template
          >
          <template #info>
            <div>
              ID: {{ student.user && student.user.talent_id }}&emsp;Трек:
              {{ student.trackTitle }}
            </div>
            <span v-if="student.gradeText"> {{ student.gradeText }}&emsp;</span
            >{{ student.education_title }}
          </template>
        </UserCard>
      </div>
      <div v-if="!filtredStudents.length">
        <p>
          Нет учеников по выбранным параметрам. Попробуйте
          <a
            href="#"
            class="link link--pseudo"
            @click.prevent="handleResetFilter"
            >сбросить фильтры</a
          >.
        </p>
      </div>
    </div>
    <div v-else-if="!students.length && !pending">
      <p>
        У вас пока нет учеников <br />
        <a
          href="#"
          class="link link--pseudo"
          :to="{ name: 'user-profiles' }"
          @click.prevent="handleInvite"
          >Пригласить
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import UserCard from "@/components/mentor/UserCard";
import InviteUserModal from "@/components/mentor/modals/InviteUserModal";
import { GRADE_OPTIONS, STUDENT_ALIAS } from "@/constants";
import { request } from "@/services/api";
import IndexSlider from "@/components/index-slider/IndexSlider.vue";

const ALL_TRACKS_OPTION = {
  title: "Все треки",
  id: null,
};
const ALL_GRADES_OPTION = {
  title: "Все классы",
  id: null,
};

const initialFilters = () => {
  return {
    track: null,
    grade: null,
    profile: null,
  };
};

export default {
  name: "MentorIndex",
  components: { UserCard, IndexSlider },
  metaInfo() {
    return {
      title: "Главная страница",
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },

  data() {
    return {
      pending: false,
      size: 25,
      total: 0,
      list: [],
      error: "",
      filters: initialFilters(),
      bannersPending: false,
      banners: [],
    };
  },
  computed: {
    students() {
      const tracks = this.$store.state.tracks;
      return this.list.map((student) => {
        const gradeText =
          tracks[student.track_id]?.alias !== STUDENT_ALIAS
            ? `${student.grade} класс`
            : "";
        return {
          ...student,
          gradeText,
          trackTitle: tracks[student.track_id]?.title,
        };
      });
    },
    slides() {
      const { banners } = this;
      return banners.map((n) => {
        return {
          type: "banner",
          ...n,
        };
      });
    },
    filtredStudents() {
      const { students, filters } = this;
      let list = [...students];
      if (filters.track?.id) {
        list = list.filter((n) => n.track_id === filters.track?.id);
      }
      if (filters.grade?.id) {
        list = list.filter((n) => n.grade === filters.grade?.id);
      }
      if (filters.profile?.id) {
        list = list.filter((n) => n.profiles?.includes(filters.profile.id));
      }
      return list;
    },
    trackOptions() {
      const list = Object.values(this.$store.state.tracks).map((n) => {
        return {
          title: n.title,
          id: n.id,
        };
      });
      return [ALL_TRACKS_OPTION, ...list];
    },
    gradeOptions() {
      return [
        ALL_GRADES_OPTION,
        ...GRADE_OPTIONS.map((n) => {
          return { title: `${n} класс`, id: n };
        }),
      ];
    },
    /**
     * Список профилей берем из списка студентов,
     * т.к их может быть овер много
     */
    profilesOptions() {
      const ids = [
        ...new Set(
          this.students.reduce((acc, v) => {
            if (v.profiles?.length) {
              return [...acc, ...v.profiles];
            }
            return acc;
          }, [])
        ),
      ];
      const list = ids.reduce((acc, pid) => {
        const profile = this.$store.state.profile.profiles[pid];
        if (profile) {
          acc.push({
            title: profile.title,
            id: pid,
          });
        }
        return acc;
      }, []);

      return [
        { title: "Все профили", id: null },
        ...list.sort((a, b) => {
          return a.title > b.title ? 1 : -1;
        }),
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.$store.dispatch("mentor/getMyOrganizations");
      this.getAllStudents();
      this.getBanners();
    },
    /**
     * Получаем список баннеров
     * по всему треку
     */
    async getBanners() {
      this.bannersPending = true;
      try {
        const data = await this.$store.dispatch("getBanners");
        this.banners = data.items;
      } catch (error) {
        // do nothing
      }
      this.bannersPending = false;
    },
    async getAllStudents() {
      const season = this.$store.state.season?.id;
      const { size } = this;
      this.pending = true;
      try {
        const config = {
          method: "GET",
          url: "/mentor/students",
          params: {
            season,
            size: size,
            page: 1,
          },
        };
        const { data } = await request(config);
        let students = [...data.items];
        this.total = data.total;
        /**
         * Если записей больше чем размер страницы, то дозапросим остальные страницы
         */
        if (data.total > size) {
          const pagesCount = Math.ceil(data.total / size);
          const requests = [];
          // с 2 - потому что первую страницу мы уже получили
          for (let i = 2; i <= pagesCount; i++) {
            config.params.page = i;
            requests.push(request(config));
          }

          const resp = await Promise.all(requests);
          students = resp.reduce((acc, value) => {
            return [...acc, ...value.data.items];
          }, students);
        }
        this.list = students;
        if (students.length) {
          const storeData = students.reduce((acc, v) => {
            acc[v.user.id] = v;
            return acc;
          }, {});
          this.$store.commit("mentor/SET_STUDENTS", storeData);
          this.$store.commit("mentor/SET_STATE", {
            key: "totalStudents",
            value: this.total,
          });
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.pending = false;
      }
    },
    handleUserClick(id) {
      this.$router.push({
        name: "mentor-student",
        params: {
          id,
        },
      });
    },
    handleResetFilter() {
      this.filters = initialFilters();
    },
    handleInvite() {
      this.$modal.show(
        InviteUserModal,
        {},
        { adaptive: true, scrollable: true, minHeight: 400, height: "auto" }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.u-page {
  &__card {
    cursor: pointer;

    &:focus {
      border-color: @deep-blue;
    }
  }
}

.link-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__link {
    text-decoration: none;
    color: @link-color;
    font-weight: bold;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
